import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import Routes from './routes'
import {store, history} from './store/store';
const routes_ = require('./routes/index').default(store);


export default class AppContainer extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="app">
            <Routes routes={routes_} store={store} history={history}></Routes>
          </div>
        </ConnectedRouter>
      </Provider>
    )
  }
}

