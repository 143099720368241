import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {VIFAA_COUNTRIES} from "../../../../utils/constants";
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";

const createRoute = (props) => {
  const HomeComponent = asyncComponent({
    resolve: () => new Promise(resolve => {
      import('./modules/apparentConsumption').then(reducer => {
        injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.APPARENT_CONSUMPTION}`, reducer: reducer.default})
        import('./modules/qCropLandUnderProductionMap').then(reducer => {
          injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.Q_CROPLAND_UNDER_PRODUCTION}`, reducer: reducer.default})
          import('./modules/domesticUreaConsumption').then(reducer => {
            injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.UREA_CONSUMPTION}`, reducer: reducer.default})
            import('./modules/averageConsumptionByProductTon').then(reducer => {
              injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.CONSUMPTION_BY_PRODUCT}`, reducer: reducer.default})
              import('./modules/averageConsumptionByNutrientTon').then(reducer => {
                injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.CONSUMPTION_BY_NUTRIENT}`, reducer: reducer.default})
                import('./modules/fertUseByCrop').then(reducer => {
                  injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.USE_BY_CROP}`, reducer: reducer.default})
                  import('./modules/nutrientUseByCrop').then(reducer => {
                    injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.NUTRIENT_USE_BY_CROP}`, reducer: reducer.default})
                    import('./modules/topFertConsumingCrops').then(reducer => {
                      injectReducer({key: `${VIFAA_COUNTRIES.NIGERIA}_${PAGES.USE}_${MODULE_NAMES.TOP_FERTILIZER_CONSUMING_CROPS}`, reducer: reducer.default})
                      import('./layouts/mainLayout').then(view => {
                        resolve(view);
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    })
  })
  return (<HomeComponent></HomeComponent>)
}

export default createRoute

